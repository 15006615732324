// Styles

// Main styles

@import "./config/default/bootstrap.scss";
@import "./config/default/app.scss";

// Minton Icons
@import "./icons.scss";


.scroll_shift {scroll-margin-top: 80px;}

.fullpage {
    //display: block;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    //height: 100vh;
    //background-size: contain;
    //background-repeat: no-repeat no-repeat;
    //background-position: center center;
    // background-color: black;
  }

  span.email b {
    display: none;
  }